// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.2rem",
          marginTop: theme.spacing(1)
        }
      }
    }
  }
}
