"use client"
import { common } from "@mui/material/colors"
import { alpha } from "@mui/material/styles"
import { error, primary, info, secondary, success, warning } from "./colors"

export function createPalette() {
  return {
    action: {
      active: secondary[500],
      disabled: alpha(secondary[900], 0.38),
      disabledBackground: alpha(secondary[900], 0.12),
      focus: alpha(secondary[900], 0.16),
      hover: alpha(secondary[900], 0.04),
      selected: alpha(secondary[900], 0.12)
    },
    background: {
      default: common.white,
      paper: common.white
    },
    divider: "#F2F4F7",
    error,
    info,
    mode: "light",
    primary,
    secondary: {
      main: secondary[400],
      ...secondary
    },
    success,
    text: {
      primary: secondary[900],
      secondary: secondary[500],
      disabled: alpha(secondary[900], 0.38)
    },
    warning
  }
}
