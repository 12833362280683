"use client"
import {
  createTheme as createMuiTheme
} from "@mui/material/styles"

import { createPalette } from "./createPalette"
import { createShadows } from "./createShadows"
import { createTypography } from "./createTypography"
import componentsOverride from "./overrides"

function createCustomTheme() {
  const palette = createPalette()
  const shadows = createShadows()
  const typography = createTypography()

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        xxs: 480,
        sm: 568,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1536
      }
    },
    palette,
    shadows,
    shape: {
      borderRadius: 16
    },
    typography,
    variables: {
      fields: {
        padding: "8px 12px",
        lineHeight: 1.572,
        minHeight: 32,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: "1.4rem",
        borderRadius: 8,
        border: "1px solid #D6D9DF",
        backgroundColor: "white"
      }
    },
  })
}

const theme = createCustomTheme();
theme.components = componentsOverride(theme);

export default theme;

// function ThemeCustomization({ children }) {
//   const themes = createCustomTheme()
//   themes.components = componentsOverride(themes)

//   return (
//     <div className={FontPoppins.className}>
//       <StyledEngineProvider injectFirst>
//         <ThemeProvider theme={themes}>
//           <CssBaseline />
//           {children}
//         </ThemeProvider>
//       </StyledEngineProvider>
//     </div>
//   )
// }

// export default ThemeCustomization
