// ==============================|| OVERRIDES - Stack ||============================== //

export default function Stack() {
  return {
    MuiStack: {
      defaultProps: {
        direction: "row"
      }
    }
  }
}
