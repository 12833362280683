// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          "&:active": {
            boxShadow: "none"
          }
        },
        sizeLarge: {
          fontSize: "1.2rem",
          height: 48
        },
        light: {
          color: theme.palette.primary[400],
          backgroundColor: theme.palette.primary[200],
          borderColor: theme.palette.primary[300],
          "&.MuiChip-lightError": {
            color: theme.palette.error[400],
            backgroundColor: theme.palette.error[200],
            borderColor: theme.palette.error[300]
          },
          "&.MuiChip-lightSuccess": {
            color: theme.palette.success[400],
            backgroundColor: theme.palette.success[200],
            borderColor: theme.palette.success[300]
          },
          "&.MuiChip-lightWarning": {
            color: theme.palette.warning[400],
            backgroundColor: theme.palette.warning[200],
            borderColor: theme.palette.warning[300]
          }
        }
      }
    }
  }
}
