// material-ui
import { alpha } from "@mui/material/styles"

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    }
  }
}
