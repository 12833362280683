// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function MuiGrid(theme) {
  return {
    MuiGrid: {
      styleOverrides: {
        root: {},
        container: {
          padding: 0,
          margin: 0,
          width: "100%",
          paddingLeft: 0,
          paddingTop: 0
        }
      }
    }
  }
}
