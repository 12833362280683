// import { Poppins } from '@next/font/google';
import { FontPoppins } from "../theme/fonts/index.js"

// export const poppins = Poppins({
//   display: "swap",
//   subsets: ["latin"],
//   weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
// });

export const createTypography = () => {
  return {
    fontFamily: [
      "SVN-Poppins",
      "-apple-system",
      '"Segoe UI"',
      "Roboto",
      "sans-serif"
    ].join(","),
    fontDisplay: "optional",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    body: {
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: "20px"
    },
    body1: {
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: "20px"
    },
    body2: {
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: 1.6
    },
    button: {
      fontWeight: 600
    },
    caption: {
      fontSize: "1.4rem",
      fontWeight: 500,
      lineHeight: 1.66
    },
    subtitle1: {
      fontSize: "1.2rem",
      fontWeight: 500,
      lineHeight: "20px"
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "20px"
    },
    overline: {
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase"
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.2rem",
      lineHeight: 1.2
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.8rem",
      lineHeight: 1.2
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.4rem",
      lineHeight: 1.2
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.2
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.2
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.2
    }
  }
}
