"use client"

import { alpha } from "@mui/material/styles"

const withAlphas = color => {
  return {
    ...color,
    alpha4: alpha(color[400], 0.04),
    alpha8: alpha(color[400], 0.08),
    alpha12: alpha(color[400], 0.12),
    alpha30: alpha(color[400], 0.3),
    alpha50: alpha(color[400], 0.5)
  }
}

const defaultColor = {
  black: "#000000",
  white: "#ffffff"
}

export const primary = withAlphas({
  50: "#F6F6F6",
  100: "#E8E8E8",
  200: "#D0D0D0",
  300: "#B9B9B9",
  400: "#A1A1A1",
  500: "#8A8A8A",
  600: "#727272",
  700: "#434343",
  800: "#373737",
  900: "#141414"
})

export const secondary = withAlphas({
  50: "#fff0f6",
  100: "#ffd6e7",
  200: "#ffadd2",
  300: "#ff85c0",
  400: "#f759ab",
  500: "#eb2f96",
  600: "#FF85C0",
  700: "#9e1068",
  800: "#780650",
  900: "#000000"
})

export const success = withAlphas({
  50: "#f0f5ff",
  100: "#d6e4ff",
  200: "#adc6ff",
  300: "#adc6ff",
  400: "#597ef7",
  500: "#2f54eb",
  600: "#1d39c4",
  700: "#10239e",
  800: "#061178",
  900: "#030852"
})

export const warning = withAlphas({
  50: "#fff2e8",
  100: "#ffd8bf",
  200: "#ffbb96",
  300: "#ff9c6e",
  400: "#ff7a45",
  500: "#fa541c",
  600: "#d4380d",
  700: "#ad2102",
  800: "#871400",
  900: "#610b00"
})

export const info = withAlphas({
  50: "#f6ffed",
  100: "#d9f7be",
  200: "#b7eb8f",
  300: "#95de64",
  400: "#73d13d",
  500: "#52c41a",
  600: "#389e0d",
  700: "#237804",
  800: "#135200",
  900: "#092b00"
})

export const error = withAlphas({
  50: "#fff1f0",
  100: "#ffccc7",
  200: "#ffa39e",
  300: "#ff7875",
  400: "#ff4d4f",
  500: "#f5222d",
  600: "#cf1322",
  700: "#a8071a",
  800: "#820014",
  900: "#5c0011"
})
